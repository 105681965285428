.music {
    .banner {
      background-color: rgb(242, 242, 242);
      height: 425px;
      display: flex;
      justify-content: center;
      .image {
        width: 1024px;
        height: auto;
      }
    }
    
    .wrapper {
      display: flex;
      justify-content: space-between;
      .center {
        width: 1024px;
        display: flex;
        justify-content: center;
      }
    }

    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;      
    }
}
  