.textImage {
    display: grid;
    grid-template-columns: repeat(10, 1fr); // 10 columns.
    margin: 40px 0 40px 0;
    max-width: 900px;
  
    .imageRight, .textLeft {
      grid-row: 1 / 2;
    }
    .imageLeft, .textRight {
        grid-row: 1 / 2;
    }
    
    .imageRight {
       width: 400px;
       grid-column: 7 / 11 ;
    }
    
    .textLeft {
      background-color: black;
      color: white;
      border: 1px solid gray;
      padding: 0 10px 10px 10px;
      grid-column: 1 / -4;
      align-self: center;
      .title {
        font-weight: bold;
        font-size: xx-large;
      }
    }

    .textRight {
        background-color: black;
        color: white;
        border: 1px solid gray;
        padding: 0 10px 10px 10px;
        grid-column: 4 / -1;
        align-self: center;
        .title {
          font-weight: bold;
          font-size: xx-large;
        }
      }
      .imageLeft {
        width: 400px;
        grid-column: 1 / 5 ; 
      }
}
