.technology {
  a {
    font-weight:lighter;
    text-decoration: underline;
    color: rgb(0, 50, 159);
  }

  .banner {
    background-color: rgb(242, 242, 242);
    height: 425px;
    display: flex;
    justify-content: center;
    .image {
      width: 1024px;
      height: auto;
    }
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    .center {
      width: 1024px;
      display: flex;
      justify-content: center;
    }
  }
}
  