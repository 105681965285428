body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: larger;
    color: black;
}

a {
    text-decoration: none;
    color: gray;
}

a:hover {
    border-bottom: 1px solid;
    color: black;
}
