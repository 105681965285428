.musicplayer{
    border-top: 1px solid rgb(177, 177, 177);
    .audioplayer {
        background: rgb(223, 223, 223);
        .rhap_volume-bar, .rhap_volume-indicator {
            background-color: rgb(92, 92, 92);
        }
        .rhap_progress-filled,
        .rhap_progress-indicator {
            background-color: rgb(92, 92, 92) !important;
        }
        .rhap_main-controls-button svg {
            color: rgb(61, 61, 61);
            width: 100%;
            height: 100%;
          }
    }
}
