.playground {
    .center {
        background-color: lightblue;
        display: flex;
        justify-content: center;
        .neck {
            background-color: brown;
            border: 0px solid black; 
            background-position: 0px 20px;
            background-image: url("../assets/wood-1760.jpg");
            background-repeat: repeat-x;
        }
    }
    .section {
        width: 1024px;
        margin-left: auto;
        margin-right: auto;
        .center {
            background-color: green;
            display: flex;
            justify-content: center;
        }
    }
}
