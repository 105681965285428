.header {
    border-bottom: 1px solid rgb(184, 184, 184);
}

.header > div {
    padding: 1rem;
    border: 1px solid white;
    text-align: center; 
}

.header > div > div > div >  h2 {
    font-weight: 800;
    font-size: 25px;
}

.header > div > div > div > nav {
    font-size: 15px;
    color: gray;
}

.header > div > div > div > h2 > a {
    text-decoration: none;
    color:black;
}

.header > div > div > div > h2 > a:hover {
    color: black;
    border-bottom: 0px solid;
}
