.userscaledictionary {
    width: 870px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(235, 235, 235);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;

    .scaleTable {
        width: 100%;
        #formula {
            width: 30%;
        }
        #name {
            width: 60%;
        }
        #action {
            width: 10%;
        }
    }

    button, select, input {
       margin: 5px;
    }

    input {
        height: 42px;
        border-radius: 5px;
        border: 1px solid lightgray;
        padding: 10px;
    }

    input:disabled {
        background-color: white;
        -webkit-text-fill-color: black;
        opacity: 1;
    }

    select {
        height: 42px;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid lightgray;
        color: rgb(63, 63, 63);
        background-color: white;
    }

    tr {
        border-bottom: .7pt solid lightgrey;
        border-top: .7pt solid lightgrey;
    }

    .modifier { //Natural, sharp, flat drop down.
        width: 95px;
    }
}
