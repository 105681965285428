.guitarscales {
  .banner {

    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  .wrapper {
    display: flex;
    justify-content: space-between;
    .center {
      width: 1024px;
      display: flex;
      justify-content: center;
    }
  }
}
