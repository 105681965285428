.keyboardscaleviewer {
    .content {
        max-width: 1026px;
        margin-left: auto;
        margin-right: auto;
        .keys {
            padding-left: 100px;
            background-color: white;
            .canvas {
                border: 0px solid black; 
                background-color: black;
            }
        }
    }
}
