.accomplishment {
    .year {
        font-weight:bold;
        color: rgb(228, 166, 34);
        padding: 10px;
    }
    .title {
        font-weight:bold;
        color: darkgray;
        padding: 10px;
    }
    .text {
        color: gray;
        padding: 10px;
    }
    a {
        color: black;
        font-weight: bold;
    }
}