.scaleheader {
    background-color: white;
    font-weight: bolder;
    padding-top: 15px;
    padding-bottom: 15px;
    .formula {
        font-size: 15px;
        font-weight: 10;
        color: gray;
    }
}
