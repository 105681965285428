.guitarscaleselector {
    background-color: white;
    padding-top: 10px;
    padding-bottom: 0px;
    .key {
        width: 100px;
        padding: 10px;
    }
    .scale {
        width: 220px;
        padding: 10px;
    }
    .tunning {
        width: 220px;
        padding: 10px;
    }
    .stringOption {
        width: 100px;
        padding: 10px;
    }
    .letterOption {
        width: 150px;
        padding: 10px;
    }
    .view {
        width: 100px;
        padding: 10px;
    }
}
