.blogit  {
    padding: 0px 20px 0px 20px;
    .header {
        padding-top:10px;
        display: block;
        font-size: xx-large;
        color: gray;
    }
    .date {
        padding-bottom: 10px;
        display: block;
        font-size: small;
        color: gray;
    }
    .body {
        display: block;
        color: gray;
    }
    .youtube {
        text-align: center; 
    }
    .image {
        padding: 10px 0 10px 0;
        text-align: center; 
    }
}
